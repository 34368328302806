jQuery(function () {
    iscroll_init();

    jQuery('.table-wrapper').each(function() {
        update_shadows(this);
    });

    jQuery(window).resize(function() {
        jQuery('.table-wrapper').each(function() {
            update_shadows(this);
        });
    });
});

function iscroll_init() {
    if (jQuery('#main table:not(.rdTable)').length > 0) {
        jQuery('#main table:not(.rdTable)').each(function(index) {
            jQuery(this).wrap('<div class="table-wrapper" id="' + 'table-wrapper-' + index + '" data-scrollable-table></div>');
        });

        jQuery('.table-wrapper').each(function() {
            var iscroll = new IScroll('#' + jQuery(this).attr('id'), {
                interactiveScrollbars: true,
                fadeScrollbars: false,
                scrollbars: true,
                scrollX: true,
                scrollY: false,
                probeType: 3,
                eventPassthrough: true
            })

            iscroll.on('scroll', update_scroll_status);
            iscroll.on('scrollEnd', update_scroll_status);
        });
    }
}

function update_scroll_status() {
    if (this.x >= 0) {
        jQuery('#' + jQuery(this.wrapper).attr('id') + '.table-scrollable').removeClass('scrollable-left');
    } else {
        jQuery('#' + jQuery(this.wrapper).attr('id') + '.table-scrollable').addClass('scrollable-left');
    }
}

function update_shadows(element) {
    if (is_overflowing(element)) {
        jQuery(element).addClass('table-scrollable');
    } else {
        jQuery(element).removeClass('table-scrollable');
    }
}

function is_overflowing(element) {
    var currentOverflow = jQuery(element).css('overflow');
    if (!currentOverflow || currentOverflow === 'visible') {
        jQuery(element).css('overflow', 'hidden');
    }

    var isOverflowing = element.clientWidth < element.scrollWidth;
    jQuery(element).css('overflow', currentOverflow);
    return isOverflowing;
}
