var uiVideoHeader = function (params) {

    //global variables set from outside:
    var _params = {
        component: jQuery('.component-video-marquee')
    };
    //global variables set from inside:
    var _global = {
        body : jQuery('body')
    }

    jQuery.extend(_params, params);

    //extend params

    _params.trigger = _params.component.find('.ui-play');
    _params.modal = jQuery("[data-modal='" + _params.component.attr('data-modal-trigger') + "']");
    _params.modal.close = _params.modal.find('.ui-modal-close');
    _params.modal.player = _params.modal.find('iframe');

    var _this = {};

    _this.init = function () {
        console.log("uiVideoHeader :: init");

        //init


        //attach event listeners
        _params.trigger.on('click', function () {

            _params.modal.addClass('is-open');
            _global.body.addClass('modal-open');

            initVimeo(_params.modal.player);

        });

        _params.modal.close.on('click', function () {
            _params.modal.removeClass('is-open');
            _global.body.removeClass('modal-open');

            pauseVimeo(_params.modal.player);
        });

    };

    var initVimeo = function (video) {
        post('play');
    }

    var pauseVimeo = function (video) {
        post('pause');
    }


    var playerOrigin = '*';
    var status = jQuery('.status');

    // Listen for messages from the player
    if (window.addEventListener) {
        window.addEventListener('message', onMessageReceived, false);
    }
    else {
        window.attachEvent('onmessage', onMessageReceived, false);
    }

    // Handle messages received from the player
    function onMessageReceived(event) {
        // Handle messages from the vimeo player only
        if (!(/^https?:\/\/player.vimeo.com/).test(event.origin)) {
            return false;
        }

        if (playerOrigin === '*') {
            playerOrigin = event.origin;
        }

        var data = JSON.parse(event.data);

        switch (data.event) {
            case 'ready':
                onReady();
                break;

            case 'playProgress':
                onPlayProgress(data.data);
                break;

            case 'pause':
                onPause();
                break;

            case 'finish':
                onFinish();
                break;
        }
    }

    // Helper function for sending a message to the player
    function post(action, value) {
        var data = {
            method: action
        };

        if (value) {
            data.value = value;
        }

        var message = JSON.stringify(data);
        _params.modal.player[0].contentWindow.postMessage(message, playerOrigin);
    }

    function onReady() {
        status.text('ready');

        post('addEventListener', 'pause');
        post('addEventListener', 'finish');
        post('addEventListener', 'playProgress');
    }

    function onPause() {
        status.text('paused');
    }

    function onFinish() {
        status.text('finished');
    }

    function onPlayProgress(data) {
        status.text(data.seconds + 's played');
    }

    _this.init();
    return _this;

}
jQuery(function () {

    jQuery('.component-video-marquee').each(function (k, v) {
        uiVideoHeader({ component: jQuery(v) });
    });

});
