var uiNewsDisclaimer = function (itemSelector) {

    jQuery(itemSelector).click(function (e) {
        e.preventDefault();
        var url = jQuery(this).attr('href');

        jQuery.colorbox({
            inline: true,
            href: '#disclaimer'
        });

        jQuery("#disclaimer-confirm").click(function () {
            location.href = url;
        });

        jQuery("#disclaimer-decline").click(function () {
            jQuery.colorbox.close();
        });
    });

}

var uiNewsDisclaimerAlt = function (itemSelector) {

    jQuery(itemSelector).click(function (e) {
        e.preventDefault();
        var url = jQuery(this).attr('href');

        jQuery.colorbox({
            inline: true,
            href: '#disclaimer-alt'
        });

        jQuery("#disclaimer-alt-confirm").click(function () {
            location.href = url;
        });

        jQuery("#disclaimer-alt-decline").click(function () {
            jQuery.colorbox.close();
        });
    });

}

var uiNewsDisclaimerAlt2 = function (itemSelector) {

    jQuery(itemSelector).click(function (e) {
        e.preventDefault();
        var url = jQuery(this).attr('href');

        jQuery.colorbox({
            inline: true,
            href: '#disclaimer-alt2'
        });

        jQuery("#disclaimer-alt2-confirm").click(function () {
            location.href = url;
        });

        jQuery("#disclaimer-alt2-decline").click(function () {
            jQuery.colorbox.close();
        });
    });

}

// Read a page's GET URL variables and return them as an associative array.
function getUrlVars()
{
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

var uiNewsDisclaimerPageLoad = function() {

    jQuery(document).ready(function() {

		var queryVars = getUrlVars();

		if(queryVars.disclaimer) {

			var clean_uri = location.protocol + "//" + location.host + location.pathname;
			window.history.replaceState({}, document.title, clean_uri);

		} else {

			var url = 'https://www.almamedia.fi';
			var element = jQuery('#disclaimer-pageload');

			if(element.length) {

				jQuery.colorbox({
		            inline: true,
		            href: '#disclaimer-pageload'
		        });

		        jQuery("#disclaimer-pageload-confirm").click(function () {
					jQuery.colorbox.close();

		        });

		        jQuery("#disclaimer-pageload-decline").click(function () {
		            location.href = url;
		        });

			}

		}


    });

}
