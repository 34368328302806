var UiLanguageSelector = function (params) {


    //global variables set from outside:
    var _params = {
        component: jQuery('.ui-langauge-selector'),
        mobileComponent: jQuery('.ui-langauge-selector-mobile'),
        toggledClass : 'active'
    };
    _params.component.trigger = _params.component.find('.trigger');
    _params.component.toggleContainer = _params.component.find('.dropdown');
    //global variables set from inside:
    var _global = {
        event: 'click',
        window: jQuery(window)
    }

    jQuery.extend(_params, params);

    var _this = {};

    _this.init = function () {
        console.log("UiLanguageSelector :: init");

        //init

        if (navigator.userAgent.indexOf('Mac OS X') != -1) {
            jQuery("body").addClass("mac");
        } else {
            jQuery("body").addClass("pc");
        }

        _params.component.trigger.on(_global.event, function (e) {

            _params.component.toggleClass(_params.toggledClass);

        });

        _params.component.on(_global.event, function (e) {
            e.stopPropagation();
        });

        _global.window.on(_global.event, function (e) {

            if (_params.component.hasClass(_params.toggledClass)) {
                _params.component.removeClass(_params.toggledClass)
            }

        });

        //implement the language selector for mobile
        _params.component.toggleContainer.find('a').each(function (k, v) {
            jQuery(v).clone().appendTo(_params.mobileComponent.find('.dropdown-menu')).wrap('<li></li>');
        });

    };


    //private methods
    var _private = function () {
    }

    //public methods
    _this.public = function () {
    }
    _this.init();

    return _this;

}

jQuery(function () { var uiLanguageSelector = UiLanguageSelector(); })



