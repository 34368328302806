var ALMASmartSticky = function (params) {


    //global variables set from outside:
    var _params = {
        delay: 300,
        scrollTimeout: 100,
        element: jQuery('.ui-sticky'),
        bodyCSS: 'ui-smart-sticky',
        sticky: false,
        mainContainer: jQuery('#main'),
        resizableNode: 'dropdown-menu',
        mobileBreakpoint: 960
    };

        _params.elementWrpr = jQuery('#navigation');
        _params.elementPos = _params.element.offset();
        _params.elementWrprPos = _params.elementWrpr.offset();
        _params.dropdownTrigger = _params.element.find('li.alma-dropdown-submenu');


    //global variables set from inside:
    var _global = {
        window: jQuery(window),
        body : jQuery("body"),
        event: "scroll",
        dropdownEv: "click",
        scrollDirection: 'down',
        lastScroll: 0,
        parentHeight: _params.elementWrpr.innerHeight(),
        step: 0,
        dropdownOpen: false
    }

    jQuery.extend(_params, params);

    var _this = {};

    var _init = function () {
        console.log("SmartSticky :: init");

        if (_params.element.length <= 0) return;

        //navigation wrapper must be as big as out inner navigation
        _params.elementWrpr.height(_global.parentHeight);
        _params.element.parent('.ui-navigation').addClass('is-sticky show');

        //event listeners

        _global.window.on(_global.event, function (e) {

            clearTimeout(jQuery.data(this, 'scrollTimer'));

            jQuery.data(this, 'scrollTimer', setTimeout(function () {

                //check the direction of the scroll
                if (_global.window.scrollTop() > _global.lastScroll) _this.stickyMovement('down');
                else _this.stickyMovement('up');

                //check if stickiness in required
                if (_global.window.scrollTop() > _params.elementWrprPos.top && !_params.sticky) _this.stickNavigation();
                else if (_global.window.scrollTop() <= _params.elementWrprPos.top && _params.sticky) _this.unStickNavigation();


            }, _params.scrollTimeout));

        });

        _params.dropdownTrigger.one(_global.dropdownEv, function (e) {

            //attach the correct sizes of the uls, but just ones

            var _el = jQuery(this);

            setTimeout(function () {
                _el
                    .find('>.dropdown-menu')
                    .attr('data-original-height', _el.find('>.dropdown-menu').innerHeight());
            }, 10)


        });

        _params.dropdownTrigger.on(_global.dropdownEv, function (e) {

            var _el = jQuery(this);

            _el.siblings().removeClass('alma-open');
            _el.addClass('alma-open');

            //correct navigation sizes:

            setTimeout(function () {
                _resizeDropdownMenu(_el);
            }, 20)



        });

        _global.window.on('click', function (e) {
            if (jQuery('body').hasClass('ui-mobile-navigation') && jQuery(window).width() < _params.mobileBreakpoint) return;

            jQuery('.navbar-nav').removeClass('light');
            jQuery('.alma-dropdown.active').removeClass('active');
            jQuery('body').removeClass('opened-navi');
            jQuery('.ui-navigation').css('top', 'inherit');
        });

    }();


    //private methods
    var _resizeDropdownMenu = function (currentElement) {
        //check if the previous item is shorter than our next item
        //if it is, previous item gets the height of our cuurent one.
        //If not, our current node gets previous item's height:

        //the node of interest is 'dropdown-menu' :: _params.resizableNode
        var biggestHeight = 0;

        var elementPrev = currentElement.parents('.dropdown-menu'),
            elementNext = currentElement.find('> .dropdown-menu');

        elementPrev.each(function (k, v) {
            if (parseInt(jQuery(v).attr('data-original-height')) > biggestHeight) {
                biggestHeight = parseInt(jQuery(v).attr('data-original-height'));
            }
        });

        if (parseInt(elementNext.attr('data-original-height')) > biggestHeight) {
            biggestHeight = parseInt(elementNext.attr('data-original-height'));
        }

        jQuery('.alma-open > ul').each(function (k, v) {
            if (parseInt(jQuery(v).attr('data-original-height')) > biggestHeight) {
                biggestHeight = parseInt(jQuery(v).attr('data-original-height'));
            }
        });


        elementPrev.height(biggestHeight);
        elementNext.height(biggestHeight);
    }


    //public methods
    _this.stickNavigation = function () {
        _params.sticky = true;
        _global.body.addClass(_params.bodyCSS);
        _params.elementWrpr.css('max-height', 100);
    }

    _this.unStickNavigation = function () {
        _params.sticky = false;
        _global.body.removeClass(_params.bodyCSS);
        _params.elementWrpr.css('max-height', _global.parentHeight);
    }

    _this.stickyMovement = function (direction) {

        var top = parseInt(jQuery('.ui-navigation').css('top'));

        if (!jQuery('body').hasClass('opened-navi')) {
            if (direction == 'down') {
                _global.scrollDirection = 'down';
                _params.element.parent('.ui-navigation').removeClass('show');
                _global.body.addClass('navi-small');
            } else {
                _global.scrollDirection = 'up';
                _params.element.parent('.ui-navigation').addClass('show');
                _global.body.removeClass('navi-small');
            }
        } else if (top != 0) {
            var difference = jQuery(window).scrollTop() - top;
            if (direction == 'up' && difference < 0) {
                _global.window.trigger("click");
            }
        }

        _global.lastScroll = _global.window.scrollTop();
    }


    return _this;

}
