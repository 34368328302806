
var UiTabs = function (params) {

    //global variables set from outside:
    var _params = {
        component: jQuery('.ui-tabs'),
        headerItem: '.ui-tab-header-item',
        contentItem: '.ui-tab-content',
        globalEvent : 'click'
    };

    jQuery.extend(_params, params);

    _params.component.items = _params.component.find('.ui-tab-holder');
    _params.component.headerTrigger = _params.component.find(_params.headerItem);
    _params.component.contentItems = _params.component.find(_params.contentItem);

    //global variables set from inside:
    var _global = {
        headerHolder: jQuery('<div class="tab-header">'),
        window: jQuery(window),
        html: jQuery('html, body'),
        mobileUp: 736,
        activeClass: 'active',
        data : 'data-index'
    }



    var _this = {};

    _this.init = function () {
        console.log("uiTabs :: init");

        //init

        _createHeaders();
        _setInitialTabs();


        //event listeners
        _params.component.headerTrigger.on(_params.globalEvent, _this.switchTabs);

    };

    //private methods
    var _createHeaders = function () {

        _params.component.items.each(function (k, v) {
            jQuery(v).find(_params.headerItem).attr(_global.data, k);
            jQuery(v).find(_params.headerItem).clone().appendTo(_global.headerHolder);
        });

        _params.component.prepend(_global.headerHolder);
        _params.component.headerTrigger = _params.component.find(_params.headerItem);

    }

    var _setInitialTabs = function () {

        _toggleTabs(0);

    }

    var _toggleTabs = function (index) {

        _params.component.headerTrigger.removeClass(_global.activeClass);
        _params.component.contentItems.removeClass(_global.activeClass);
        jQuery(_params.component.headerTrigger[index]).addClass(_global.activeClass);
        jQuery(_params.component.contentItems[index]).addClass(_global.activeClass);
    }

    var _scrollToContainer = function () {

        _global.html.animate({
            scrollTop: _params.component.offset().top
        }, 400);

    }


    //public methods



    _this.switchTabs = function (event) {
        var target = jQuery(event.currentTarget),
            index = target.attr(_global.data);

        _toggleTabs(index);

        if (_global.window.width() <= _global.mobileUp) _scrollToContainer();
    }

    _this.toggleMobileTabs = function ( event ) {
        _global.headerHolder.toggleClass('active');
    }

    _this.init();
    return _this;

}
jQuery(function () {

    if (jQuery('.sfPageEditor').length > 0) {
        return;
    }

    jQuery('.ui-tabs').each(function (k, v) {
        UiTabs({component : jQuery(v)});
    });



});
