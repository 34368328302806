
var UiTransparentText = function (params) {

    //global variables set from outside:
    var _params = {
        component: jQuery('.component-transparent'),
        textSize: 42,
        mobileBreakpoint: 667,
        tableBreakpoint: 768,
        desktopBreakpoint : 1024
    };

    jQuery.extend(_params, params);

    //params extends:
    _params.displayText = _params.component.data('display-text');


    var _this = {};

    _this.init = function () {

        _setTextSize();
        jQuery(window).resize(function () {
            _setTextSize();
            _this.createSVG(function (_html) {
                _params.component.html(_html);
            })
        });

        //init
        _this.createSVG(function (_html) {
            _params.component.html(_html);
        })


    };

    _setTextSize = function () {
        var windowSize = jQuery(window).innerWidth();

        switch (true) {
            case windowSize <= _params.mobileBreakpoint:
                _params.textSize = 29
                break;
            case windowSize <= _params.tableBreakpoint:
                _params.textSize = 43
                break;
            case windowSize <= _params.desktopBreakpoint:
                _params.textSize = 63
                break;

        }
    }

    //private methods
    var _centeredDiv = function (parent) {

        var d = document.createElement('div'),
            s = d.style;
        s.display = "table";
        s.position = "relative";
        s.zIndex = 999;
        s.top = s.left = 0;
        s.width = s.height = "100%";

        var k = document.createElement('div'),
            j = k.style;
        j.display = "table-cell";
        j.verticalAlign = "middle";
        j.textAlign = "center";
        d.appendChild(k);

        parent.appendChild(d);

        return k;
    }

    var _textWidth = function (text, font, size, cb) {
        var canvas = window.textWidthCanvas || (window.textWidthCanvas = document.createElement("canvas")),
            context = canvas.getContext("2d");
        context.font = size + (typeof size == "string" ? " " : "px ") + font;

        cb(context.measureText(text).width + 80)
    }

    //public methods

    _this.createSVG = function (cb) {

        _textWidth(_params.displayText, "Arial Black", _params.textSize, function (stringLength) {
            var stringHeight = _params.textSize + 30;

            cb ("<svg xmlns='http://www.w3.org/2000/svg' width='" + stringLength + "px' height='" + stringHeight + "px' viewBox='0 0 " + stringLength + " " + stringHeight + "'>" +
                    "<rect x='0' y='0' width='100%' height='100%' fill='#fff' mask='url(#txtSubMask)'></rect>" +
                    "<mask id='txtSubMask'>" +
                        "<rect x='0' y='0' width='100%' height='100%' fill='#fff'></rect>" +
                        "<text x='" + (stringLength / 2) + "' y='" + (_params.textSize + 10) + "' font-size='" + _params.textSize + "' text-anchor='middle' fill='#000'  font-family='HankenGrotesk-Bold'>" + _params.displayText + "</text>" +
                    "</mask>" +
                "</svg>")

        })
    }

    _this.init();
    return _this;

}
jQuery(function () {

    jQuery('.component-transparent').each(function (k, v) {
        UiTransparentText({ component: jQuery(v) });
    });



});
