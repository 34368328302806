jQuery(document).ready(function () {
    var externalLinks = jQuery('.tile-content a').filter(
        function () {
            return this.hostname && this.hostname !== location.hostname && this.hostname != 'd2asyz8lpscdp0.cloudfront.net';
        }
    );
  //  jQuery(externalLinks).addClass("ui-external-link right");
    jQuery(externalLinks).attr('target', '_blank');
});

