jQuery(document).ready(function () {
    jQuery('.hierarchical-checkbox label').css('display', 'none');

    jQuery('.hierarchical-checkbox').each(function () {
        var checkBoxText = jQuery(this).find('strong').text();
        var checkboxName = jQuery(this).find('input:checkbox')[0].name;
        jQuery(this).find('strong').replaceWith('<div class="checkbox"><label class="parent-label"><input name="' + checkboxName + '" data-sf-role="checkboxes-field-input" class="parent-checkbox" value="' + checkBoxText + '" type="checkbox">' + checkBoxText + ' </label></div>');
    });

    jQuery('.parent-label').bind('change', function () {
        var checkboxLabels = jQuery(jQuery(this).closest('.hierarchical-checkbox')).find('label:not(.parent-label)');
        var checkboxes = jQuery(jQuery(this).closest('.hierarchical-checkbox')).find('input:not(.parent-checkbox)');
        if (jQuery(this).find('.parent-checkbox').is(':checked')) {
            jQuery(checkboxLabels).css('display', 'inline').css('margin-left', '20px');
        }
        else {
            jQuery(checkboxLabels).css('display', 'none');
            jQuery(checkboxes).prop('checked', false);
        }
    });
});
